import {computed, reactive, ref} from "@vue/composition-api";
import store from "@/store";

export default function useCoinMoneyLimitation() {


        const amounts = ref([]);
        const userData = computed(()=>store.getters["user/getUserData"]);
        const getLimitsError = ref(false);
        const loadings = reactive({
            setLimitLoading: false,
            getLimitLoading: false,
        });
        const thereAreLimit = ref(false);
        const limitSession = [
            {label: "1 día", value: 1, valueType: "daily"},
            {label: "1 semana", value: 7, valueType: "daily"},
            {label: "30 días", value: 30, valueType: "daily"},
            {label: "90 días", value: 90, valueType: "daily"},
            {label: "180 días", value: 180, valueType: "daily"},
            // { label: "indeterminate", value: -1 },
        ];

        const currencyLimitation = reactive({
            status: "",
            duration: 0,
            durationType: null,
        });
        const mapLimitationItem = (item)=>{
            return {

                currency: item.split(" ")[0],
                limit: item.split(" ")[1].replace(/,/g, ""),
            }
        }

        return {
            currencyLimitation,
            limitSession,
            thereAreLimit,
            loadings,
            amounts,
            userData,
            getLimitsError,
            mapLimitationItem
        }


}