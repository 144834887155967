<template>
  <div>
    <b-modal
      v-model="modalShow"
      :title="`${ $t('limit_whitelabel')}  ${
        whitelabelData.name ? whitelabelData.name : ''
      }`"
      no-close-on-backdrop
      hide-footer
      bg-variant="white"
      shadow
      backdrop
      no-close-on-esc
      centered
      size="lg"
      @hidden="resetData"
    >
      <div>
        <b-tabs class="mx-2" content-class="mt-1" v-if="whitelabelData.currencies">
            <b-tab :title="$t('deposit')" active>
            <whitelabel-money-limitation
              type="deposit"
              :subtitle="$t('control_your_deposits')"
              :currencies="whitelabelData.currencies"
              :whitelabel-data="whitelabelData"
              @close-main-modal="setModal(false, {})"
            />
          </b-tab>
          <b-tab :title="$t('losses')">
            <whitelabel-money-limitation
              ref="lossComponent"
              type="loss"
              :subtitle="$t('control_your_losses')"
              :currencies="whitelabelData.currencies"
              :whitelabel-data="whitelabelData"
              @close-main-modal="setModal(false, {})"
            />
          </b-tab>
          <b-tab :title="$t('bet')">
            <whitelabel-money-limitation
              type="bet"
              :subtitle="$t('control_your_bets')"
              :currencies="whitelabelData.currencies"
              :whitelabel-data="whitelabelData"
              @close-main-modal="setModal(false, {})"
            />
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { BButton } from "bootstrap-vue";
import store from "@/store";
import limitationStoreModule from "@/views/pages/limitation/limitationStoreModule";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import WhitelabelMoneyLimitation from "@/views/pages/limitation/WhitelabelMoneyLimitation.vue";

export default {
  name: "WhitelabelLimitationModal",
  components: { WhitelabelMoneyLimitation, BButton, vSelect },
  directives: {
    Ripple,
  },
  setup() {
    const USER_LIMITATION_APP_STORE_MODULE_NAME = "app-user-limitation";
    if (!store.hasModule(USER_LIMITATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_LIMITATION_APP_STORE_MODULE_NAME,
        limitationStoreModule
      );
    // onUnmounted(() => {
    //   if (store.hasModule(USER_LIMITATION_APP_STORE_MODULE_NAME))
    //     store.unregisterModule(USER_LIMITATION_APP_STORE_MODULE_NAME);
    // });

    const modalShow = ref(false);

    const whitelabelData = ref({});
    const resetData = () => {
      whitelabelData.value = {};
    };

    const setModal = async (flag, data) => {
      if (flag) {
        whitelabelData.value = Object.assign({}, data);
        modalShow.value = true;
      } else {
        modalShow.value = false;
      }
    };

    return {
      modalShow,
      whitelabelData,
      setModal,
      resetData,
    };
  },
};
</script>

<style scoped></style>
