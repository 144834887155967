<template>
  <!-- <b-modal
    id="add-new-whitelabel-sidebar"
    :visible="isAddNewWhitelabelSidebarActive"
    :title="showTitleModal"
    bg-variant="white"
    shadow
    backdrop
    no-close-on-backdrop
    no-header
    hide-footer
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-whitelabel-sidebar-active', val)"
  >
    <template #default="{ hide }"> -->
  <!-- BODY -->
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <!-- Form -->
    <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
    >
      <div
          v-if="(modeEdit || modeShow) && data.client !== null"
          class="d-flex justify-content-center"
          @click="$refs.fileImageLogo.click()"
          :class="{ 'pointer-events-none': modeShow }"
      >
        <b-avatar
            class="cursor-pointer"
            size="90px"
            variant="light-success"
            :src="data.logo"
        >
          <span v-if="data.logo == '' || null || undefined">LOGO</span>
        </b-avatar>
        <input
            type="file"
            ref="fileImageLogo"
            style="display: none"
            @change="onFileChange"
        />
      </div>

      <!-- Whitelabel name -->
      <validation-provider
          v-if="isSeePermission({ permission: 'show_whitelabel_name' })"
          #default="validationContext"
          name="Name"
          rules="required"
      >
        <b-form-group label="Name" label-for="name">
          <template #label>{{ $t("labels.name") }}</template>
          <b-form-input
              id="name"
              v-model="data.name"
              :state="getValidationState(validationContext)"
              trim
              :class="{ 'pointer-events-none': modeShow }"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Description -->
      <validation-provider
          #default="validationContext"
          name="Description"
          rules=""
          v-if="(modeEdit || modeShow) && data.client !== null && isSeePermission({ permission: 'show_whitelabel_description' })"
      >
        <b-form-group label="Description" label-for="description">
          <template #label>{{ $t("labels.description") }}</template>
          <b-form-input
              id="description"
              v-model="data.description"
              :state="getValidationState(validationContext)"
              trim
              :class="{ 'pointer-events-none': modeShow }"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div v-if="(modeEdit || modeShow) && data.client !== null">
        <!-- Whitelabel host -->
        <b-form-group label="Host" label-for="host"
                      v-if="isSeePermission({ permission: 'show_whitelabel_host' })"
        >
          <v-select
              v-model="data.host"
              taggable
              multiple
              push-tags
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :class="{ 'pointer-events-none': modeShow }"
          />

          <div class="text-danger">
            {{ validHost }}
          </div>
        </b-form-group>

        <b-form-group label="HostAdmin" label-for="hostAdmin"
                      v-if="isSeePermission({ permission: 'show_whitelabel_hostadmin' })"
        >
          <v-select
              v-model="data.hostAdmin"
              taggable
              multiple
              push-tags
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :class="{ 'pointer-events-none': modeShow }"
          />

          <div class="text-danger">
            {{ validHostAdmin }}
          </div>
        </b-form-group>

        <!-- type -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_type' })"
            #default="validationContext"
            name="Type"
            rules="required"
        >
          <b-form-group label="Type" label-for="type">
            <template #label>{{ $t("labels.type") }}</template>
            <v-select
                v-model="data.type"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsType"
                :reduce="(e) => e.value"
                :state="getValidationState(validationContext)"
                :class="{ 'pointer-events-none': modeShow }"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Currencies -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_currencies' })"
            #default="validationContext"
            name="Currencies"
            rules="required"
        >
          <b-form-group :label="$t('labels.currencies')" label-for="currencie">
            <b-row>
              <b-col md="11">
                <v-select
                    v-model="data.currencies"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="currency"
                    multiple
                    :options="currenciesOptions"
                    :reduce="(e) => e.currency"
                    :closeOnSelect="false"
                    :class="{ 'pointer-events-none': modeShow }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-col>
              <b-col md="1">
                <b-button @click="getCurrencies(data.client)">+</b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </validation-provider>

        <!-- Default Currency -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_language' })"
            #default="validationContext"
            name="Default Currency"
            rules="required"
        >
          <b-form-group
              label="Default Currency"
              label-for="dCurrency"
          >
            <v-select
                v-model="data.defaultCurrency"
                :options="data.currencies"
                hint="Select default currency"
                :clearable="false"
                :multiple="false"
                :class="{ 'pointer-events-none': modeShow }"
            />
            <small class="text-danger">
              {{ validationContext.errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>

        <!-- Languages -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_language' })"
            #default="validationContext"
            name="language"
            rules="required"
        >
          <b-form-group
              v-if="isSeePermission({ permission: 'show_whitelabel_lang' })"
          >
            <template #label>Languages</template>
            <v-select
                v-model="langSelected"
                :options="data.langOptions"
                hint="Select languages"
                label="name"
                :reduce="item => item.locale"
                @option:selecting="updateDisabledOptions($event, 'selecting')"
                @option:deselected="updateDisabledOptions($event, 'deselected')"
                multiple
                :clearable="false"
                persistent-hint
            ></v-select>
          </b-form-group>
        </validation-provider>

        <b-form-group label="Default Language" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="data.langDefault"
              :options="data.langOptions"
              value-field="locale"
              text-field="name"
              disabled-field="disabled"
              :aria-describedby="ariaDescribedby"
              name="languages"
              :class="{ 'pointer-events-none': modeShow }"
          >
          </b-form-radio-group>
        </b-form-group>

        <!-- Payment methods -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_payment_methods' })"
            #default="validationContext"
            name="Payment"
        >
          <b-form-group :label="$t('labels.payment_methods')" label-for="payment_methods">
            <v-select
                v-model="data.paymentMethods"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                multiple
                :options="paymentMethodsOptions"
                :closeOnSelect="false"
                :class="{ 'pointer-events-none': modeShow }"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Available templates -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_available_templates' })"
            #default="validationContext"
            name="Payment"
        >
          <b-form-group :label="$t('available_templates')" label-for="available-templates">
            <v-select
                v-model="data.availableTemplates"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="type"
                :options="templateSlugsOptions"
                :closeOnSelect="false"
                :reduce="(e) => e.type"
                :class="{ 'pointer-events-none': modeShow }"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Products -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_products' })"
            #default="validationContext"
            name="Products"
            rules="required"
        >
          <b-form-group :label="$t('labels.products')" label-for="products">
            <v-select
                v-model="data.products"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                multiple
                :options="productsOptions"
                @option:selected="handleSelectUpdate({deselected: false})"
                @option:deselected="handleSelectUpdate({deselected: true})"
                :closeOnSelect="false"
                :class="{ 'pointer-events-none': modeShow }"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- brands -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_brands' })"
            #default="validationContext"
            name="Brands"
            rules="required"
        >
          <b-form-group :label="$t('labels.brands')" label-for="brands">
            <v-select
                v-model="data.brands"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nameAndProduct"
                multiple
                :options="brandsOptions"
                :closeOnSelect="false"
                :class="{ 'pointer-events-none': modeShow }"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!--        &lt;!&ndash; Headers &ndash;&gt;-->
        <!--        <validation-provider-->
        <!--          #default="validationContext"-->
        <!--          name="Headers"-->
        <!--          rules="required"-->
        <!--        >-->
        <!--          <b-form-group :label="$t('headers')" label-for="Headers">-->
        <!--            <v-select-->
        <!--              v-model="data.page.header"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              label="name"-->
        <!--              :options="headersOptions"-->
        <!--              :class="{ 'pointer-events-none': modeShow }"-->
        <!--            />-->
        <!--            <b-form-invalid-feedback>-->
        <!--              {{ validationContext.errors[0] }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </b-form-group>-->
        <!--        </validation-provider>-->
        <!--        -->
        <!--        &lt;!&ndash; Banners &ndash;&gt;-->
        <!--        <label v-if="!data.page.menu">Banners </label>-->
        <!--        <b-alert :show="!data.page.menu" variant="warning"-->
        <!--          >-->
        <!--          {{ $t('select_menu_before_add_banners') }}-->
        <!--        </b-alert>-->
        <!--        <validation-provider-->
        <!--          v-if="data.page.menu"-->
        <!--          #default="validationContext"-->
        <!--          name="Banners"-->
        <!--          rules="required"-->
        <!--          :class="{ 'pointer-events-none': modeShow }"-->
        <!--        >-->
        <!--          <b-badge v-for="(banner, i) in data.banners" class="rounded" :key="i">-->
        <!--            <b-button-->
        <!--              @click="data.banners.splice(i, 1)"-->
        <!--              style="margin-bottom: 5px"-->
        <!--              class="p-0 mr-1"-->
        <!--              >x-->
        <!--            </b-button>-->
        <!--            {{ banner.data.name }}-->
        <!--          </b-badge>-->
        <!--          <b-form-group label="Banners" label-for="Banners">-->
        <!--            <b-button class="w-100" @click="showAddBannerModal = true"-->
        <!--              >Add Banner-->
        <!--            </b-button>-->
        <!--            <b-modal v-model="showAddBannerModal" centered>-->
        <!--              <label for="banner">Banner</label>-->
        <!--              <v-select-->
        <!--                id="banner"-->
        <!--                :disabled="!(data.page.menu || bannerToAdd.data)"-->
        <!--                v-model="bannerToAdd.data"-->
        <!--                :options="bannersOptions"-->
        <!--                :class="{ 'pointer-events-none': modeShow }"-->
        <!--                :aria-required="$t('select_option')"-->
        <!--              >-->
        <!--                <template #selected-option="option">-->
        <!--                  {{ option.name || option.data.name }}-->
        <!--                </template>-->
        <!--                <template #option="option">-->
        <!--                  {{ option.name }}-->
        <!--                </template>-->
        <!--              </v-select>-->
        <!--              <label for="path" class="mt-1">{{ $t('pages_where_shown') }}</label>-->
        <!--              <v-select-->
        <!--                :disabled="!(pathsSelected || data.page.menu)"-->
        <!--                id="path"-->
        <!--                aria-required="the path is required"-->
        <!--                multiple-->
        <!--                v-model="pathsSelected"-->
        <!--                :options="[-->
        <!--                  { title: 'HOME', href: '/' },-->
        <!--                  ...data.page.menu.links,-->
        <!--                ]"-->
        <!--                :class="{ 'pointer-events-none': modeShow }"-->
        <!--              >-->
        <!--                <template #selected-option="option">-->
        <!--                  {{ option.href || option }}-->
        <!--                </template>-->
        <!--                <template #option="option">-->
        <!--                  {{ option.title + ":" + option.href || option }}-->
        <!--                </template>-->
        <!--              </v-select>-->
        <!--              <template #modal-footer>-->
        <!--                <b-button @click="handleAddBanner">{{ $t('tabs.add')}} {{$t('banner')}}</b-button>-->
        <!--              </template>-->
        <!--            </b-modal>-->

        <!--            <b-form-invalid-feedback>-->
        <!--              {{ validationContext.errors[0] }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </b-form-group>-->
        <!--        </validation-provider>-->

        <!--        &lt;!&ndash; Menus &ndash;&gt;-->
        <!--        <validation-provider-->
        <!--          #default="validationContext"-->
        <!--          name="Menú"-->
        <!--          rules="required"-->
        <!--        >-->
        <!--          <b-form-group label="Menus" label-for="Menus">-->
        <!--            <v-select-->
        <!--              v-model="data.page.menu"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              label="name"-->
        <!--              :options="menusOptions"-->
        <!--              :class="{ 'pointer-events-none': modeShow }"-->
        <!--            />-->
        <!--            <b-form-invalid-feedback>-->
        <!--              {{ validationContext.errors[0] }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </b-form-group>-->
        <!--        </validation-provider>-->

        <!--        &lt;!&ndash; HomePageManagement &ndash;&gt;-->
        <!--        <validation-provider-->
        <!--          #default="validationContext"-->
        <!--          name="homepage"-->
        <!--          rules="required"-->
        <!--        >-->
        <!--          <b-form-group :label="$t('home_pages')" label-for="homepage">-->
        <!--            <v-select-->
        <!--              v-model="data.page.homepage"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              label="name"-->
        <!--              :options="homePagesOptions"-->
        <!--              :class="{ 'pointer-events-none': modeShow }"-->
        <!--            />-->
        <!--            <b-form-invalid-feedback>-->
        <!--              {{ validationContext.errors[0] }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </b-form-group>-->
        <!--        </validation-provider>-->

        <!--        &lt;!&ndash; Footer &ndash;&gt;-->
        <!--        <validation-provider-->
        <!--          #default="validationContext"-->
        <!--          name="Footer"-->
        <!--          rules="required"-->
        <!--        >-->
        <!--          <b-form-group label="Footer" label-for="Footer">-->
        <!--            <v-select-->
        <!--              v-model="data.page.footer"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              label="name"-->
        <!--              :options="footersOptions"-->
        <!--              :class="{ 'pointer-events-none': modeShow }"-->
        <!--            />-->
        <!--            <b-form-invalid-feedback>-->
        <!--              {{ validationContext.errors[0] }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </b-form-group>-->
        <!--        </validation-provider>-->


        <!--Edad minima-->
        <b-form-group
            v-if="isSeePermission({ permission: 'show_whitelabel_minimum_age' })"
        >
          <template #label>{{ $t("labels.minimumAge") }}</template>
          <b-form-input
              name="minimum-age"
              v-model="data.minimumAge"
              :class="{ 'pointer-events-none': modeShow }"
              type="number"
          >
          </b-form-input>
        </b-form-group>


        <!-- Politica y privacidad -->
        <validation-provider
            #default="validationContext"
            name="politicsPrivacy"
            rules=""
            v-if="(modeEdit || modeShow) && data.client !== null && isSeePermission({ permission: 'show_whitelabel_politics_privacy' })"
        >
          <b-form-group label="politicsPrivacy" label-for="politicsPrivacy">
            <template #label>{{ $t("labels.politicsPrivacy") }}</template>
            <vue-editor
                id="politicsPrivacy"
                class="vue-editor"
                v-model="data.politicsPrivacy"
                :state="getValidationState(validationContext)"
                trim
                :class="{ 'pointer-events-none': modeShow }"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Terminos y condiciones -->
        <validation-provider
            #default="validationContext"
            name="termsConditions"
            rules=""
            v-if="(modeEdit || modeShow) && data.client !== null &&  isSeePermission({ permission: 'show_whitelabel_terms_conditions' })"
        >
          <b-form-group label="termsConditions" label-for="termsConditions">
            <template #label>{{ $t("labels.termsConditions") }}</template>
            <vue-editor
                id="termsConditions"
                class="vue-editor"
                v-model="data.termsConditions"
                :state="getValidationState(validationContext)"
                trim
                :class="{ 'pointer-events-none': modeShow }"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- juega responsable -->
        <validation-provider
            #default="validationContext"
            name="gameRes"
            rules=""
            v-if="(modeEdit || modeShow) && data.client !== null && isSeePermission({ permission: 'show_whitelabel_game_res' })"
        >
          <b-form-group label="gameRes" label-for="gameRes">
            <template #label>{{ $t("labels.gameRes") }}</template>
            <vue-editor
                id="gameRes"
                class="vue-editor"
                v-model="data.gameRes"
                :state="getValidationState(validationContext)"
                trim
                :class="{ 'pointer-events-none': modeShow }"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Tiempo en sesion -->
        <b-form-group
            v-if="isSeePermission({ permission: 'show_whitelabel_expired_session' })"
        >
          <template #label>{{ $t("labels.expiredSession") }}</template>
          <b-form-input
              name="expiredSession"
              v-model="data.expiredSession"
              :class="{ 'pointer-events-none': modeShow }"
              type="number"
          >
          </b-form-input>
        </b-form-group>

        <validation-observer>
          <!-- Tiempo de la expiracion del password en la web -->
          <validation-provider
              v-if="isSeePermission({ permission: 'show_whitelabel_expired_password' })"
              name="expiredPassword"
              rules="required|min_value:45"
              #default="validationContext"
          >
            <b-form-group>
              <template #label>{{ $t("labels.expiredPassword") }}</template>
              <b-form-input
                  name="expiredPassword"
                  v-model="data.expiredPassword"
                  :state="getValidationState(validationContext)"
                  :class="{ 'pointer-events-none': modeShow }"
                  type="number"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tiempo de alerta de expiracion del password en la web -->
          <validation-provider
              v-if="isSeePermission({ permission: 'show_whitelabel_alert_expired_password' })"
              #default="validationContext"
              name="alertExpiredPassword"
              rules="required|isBigger:@expiredPassword"
          >
            <b-form-group>
              <template #label>{{ $t("labels.alertExpiredPassword") }}</template>
              <b-form-input
                  name="alertExpiredPassword"
                  v-model="data.alertExpiredPassword"
                  :state="getValidationState(validationContext)"
                  :class="{ 'pointer-events-none': modeShow }"
                  type="number"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </validation-observer>

        <!-- User idle time -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_user_idle_time' })"
            #default="validationContext"
            name="userIdleTime"
        >
          <b-form-group label="userIdleTime" label-for="userIdleTime">
            <template #label>{{ $t("labels.userIdleTime") }}</template>
            <b-form-input
                id="inactivityTimeout"
                v-model="data.inactivityTimeout"
                :state="getValidationState(validationContext)"
                trim
                :class="{ 'pointer-events-none': modeShow }"
                type="number"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_user_idle_time' })"
            #default="validationContext"
            name="userIdleTime"
        >
          <b-form-group label="userIdleTime" label-for="userIdleTime">
            <template #label>{{ $t("labels.userIdleTime") }}</template>
            <b-form-input
                id="inactivityTimeout"
                v-model="data.inactivityTimeout"
                :state="getValidationState(validationContext)"
                trim
                :class="{ 'pointer-events-none': modeShow }"
                type="number"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Tiempo maximo que un usuario puede pasar sin loguarse en la web -->
        <b-form-group
            v-if="isSeePermission({ permission: 'show_whitelabel_user_expiration_time' })"
        >
          <template #label>{{ $t("labels.user_expiration_time") }}</template>
          <b-form-input
              name="userExpirationTime"
              v-model="data.userExpirationTime"
              :class="{ 'pointer-events-none': modeShow }"
              type="number"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
            v-if="isSeePermission({ permission: 'show_whitelabel_sfpage' })"
        >
          <template #label>SfPage</template>
          <b-form-input
              name="SfPage"
              v-model="data.SfPage"
              :class="{ 'pointer-events-none': modeShow }"
          >
          </b-form-input>
        </b-form-group>

        <!-- To find out if the whitelabel requires withdrawal of money by pin -->
        <validation-provider
            v-if="isSeePermission({ permission: 'show_whitelabel_withdraw_by_pin' })"
            #default="validationContext"
            name="withdrawals"
        >
          <b-form-group label-for="withdrawals">
            <template #label>{{ $t('withdraw_by_pin') }}</template>

            <b-form-checkbox
                style="margin-top: 10px"
                v-model="data.withdrawalByPin"
                switch
            >
              {{ $t('withdraw_by_pin') }} {{ data.withdrawalByPin ? $t('active') : $t('inactive') }}
            </b-form-checkbox>

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Global message-->
        <div
            v-if="isSeePermission({ permission: 'show_whitelabel_global_message' })"
        >
          <div>{{ $t('labels.overall_message') }}</div>

          <b-form-checkbox
              style="margin-top: 10px"
              v-model="data.useGlobalMessage"
              switch
          >
            {{ $t('labels.overall_message') }} {{ data.useGlobalMessage ? $t('active') : $t('inactive') }}
          </b-form-checkbox>

          <validation-provider
              #default="validationContext"
              name="global message"
              :rules="data.useGlobalMessage ? 'required' : ''"
          >
            <b-form-group
                label-for="global message"
            >
              <b-form-textarea
                  v-model="data.globalMessage"
                  :state="getValidationState(validationContext)"
                  :disabled='!data.useGlobalMessage'
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="d-flex flex-column"
             v-if="isSeePermission({ permission: 'show_whitelabel_money_limit' })"
        >
          <p class="font-small-3" style="margin-bottom: 7px">{{ $t('limit') }}</p>
          <b-button variant="primary" class="mr-1 mb-1 mt-0 " size="sm"
                    :disabled='loadingWhitelabel'
                    @click="setClientLimitModal(true)">
            <b-spinner v-if='loadingWhitelabel' small></b-spinner>
            <feather-icon v-else icon="DollarSignIcon"/>
            {{ loadingWhitelabel ? $t('loading_whitelabel') : $t('limit_whitelabel') }}
          </b-button>

          <b-button variant="outline-primary" class="mr-1 mb-1 mt-0 " size="sm"
                    :disabled='loadingWhitelabel'
                    @click="setTotalCoinLimitModal(true)">
            <b-spinner v-if='loadingWhitelabel' small></b-spinner>
            <feather-icon v-else icon="DollarSignIcon"/>
            {{ loadingWhitelabel ? $t('loading_whitelabel') : $t('total_coin_limits') }}
          </b-button>
        </div>

        <!-- Zona horaria -->
        <validation-provider
            name="timeZone"
            rules=""
            v-if="(modeEdit || modeShow) && data.client !== null && isSeePermission({ permission: 'show_whitelabel_time_zone' })"
            :class="{ 'pointer-events-none': modeShow }"
        >
          <b-form-group :label="$t('labels.timeZone')">
            <v-select
                v-model="data.timeZone"
                :options="options"
                label="name"
                :placeholder="$t('select_time_zone')"
            />
          </b-form-group>
        </validation-provider>
      </div>
      <!--Allow register-->
      <b-form-group
          v-if="isSeePermission({ permission: 'show_whitelabel_minimum_age' })"
      >

        <b-form-checkbox
            name="allowRegistration"
            v-model="data.allowRegistration"
            switch


        >
          {{ $t("labels.allowRegister") }}
        </b-form-checkbox>
      </b-form-group>
      <!-- Form Actions -->
      <div class="d-flex mt-2" v-if="!modeShow && data.client !== null">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
        >
          {{ modeEdit ? $t("buttons.update") : $t("buttons.add") }}
        </b-button>
        <!-- Modal para ingresar los campos requeridos o no de una WL -->
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-2"
            type="button"
            v-b-modal="'my-modal-required'"
            v-if="(modeEdit || modeShow) && data.client !== null && isSeePermission({ permission: 'show_whitelabel_required_fields' })"
        >
          {{ $t("buttons.fieldRequired") }}
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'apps-whitelabels-list' }"
        >
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>

      <div class="d-flex mt-2" v-if="modeShow && data.client !== null">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :to="{ name: 'apps-whitelabels-list' }"
        >
          {{ $t("buttons.accept") }}
        </b-button>
      </div>

      <b-modal
          id="my-modal-required"
          @ok="handleOk"
          no-close-on-backdrop
          @cancel="handleCancel"
      >
        <form ref="form" @submit.stop.prevent="handleConfirm">
          <b-table-simple striped>
            <b-tr>
              <b-th>{{ $t("labels.field") }}</b-th>
              <b-th>{{ $t("labels.required") }}</b-th>
            </b-tr>

            <b-tr>
              <b-td>{{ $t("labels.required_id") }}</b-td>
              <b-td>
                <b-form-checkbox
                    name="check-button"
                    v-model="data.required_id"
                    switch
                    inline
                ></b-form-checkbox>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>{{ $t("labels.firstname") }}</b-td>
              <b-td>
                <b-form-checkbox
                    v-model="data.fieldData.firstName"
                    switch
                    inline
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>{{ $t("labels.lastname") }}</b-td>
              <b-td>
                <b-form-checkbox
                    v-model="data.fieldData.lastName"
                    switch
                    inline
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>{{ $t("labels.email") }}</b-td>
              <b-td>
                <b-form-checkbox v-model="data.fieldData.email" switch inline>
                </b-form-checkbox>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>{{ $t("labels.address") }}</b-td>
              <b-td>
                <b-form-checkbox v-model="data.fieldData.address" switch inline>
                </b-form-checkbox>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>{{ $t("labels.phone") }}</b-td>
              <b-td>
                <b-form-checkbox v-model="data.fieldData.phone" switch inline>
                </b-form-checkbox>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>{{ $t("labels.country") }}</b-td>
              <b-td>
                <b-form-checkbox v-model="data.fieldData.city" switch inline>
                </b-form-checkbox>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>{{ $t("labels.dateofbirth") }}</b-td>
              <b-td>
                <b-form-checkbox
                    selected
                    v-model="data.fieldData.birthDate"
                    switch
                    inline
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>{{ $t("2FA") }}</b-td>
              <b-td>
                <b-form-checkbox
                    selected
                    v-model="data.fieldData.twoFA"
                    switch
                    inline
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>

            <b-tr class="pt-5">
              <b-th>{{ $t("labels.password_requirements") }}</b-th>
              <b-th></b-th>
            </b-tr>

            <b-tr>
              <b-td>
                <div class="d-inline-flex">
                  {{ $t("labels.min_length") }}
                  <b-form-input
                      type="number"
                      trim
                      :state="validationMinLength"
                      :disabled="!data.passwordSettings.minLengthActive"
                      v-model="data.passwordSettings.minLength"
                  />
                </div>
                <b-form-invalid-feedback :state="validationMinLength">
                  {{ $t("labels.min_length_limit") }}
                </b-form-invalid-feedback>
              </b-td>

              <b-td>
                <b-form-checkbox
                    selected
                    switch
                    inline
                    v-model="data.passwordSettings.minLengthActive"
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <div class="d-inline-flex">
                  {{ $t("labels.max_length") }}
                  <b-form-input
                      v-model="data.passwordSettings.maxLength"
                      type="number"
                      :state="validationMaxLength"
                      :disabled="!data.passwordSettings.maxLengthActive"
                      id="max"
                      trim
                  />
                </div>
                <b-form-invalid-feedback :state="validationMaxLength">
                  {{ $t("labels.max_length_limit") }}
                </b-form-invalid-feedback>
              </b-td>

              <b-td>
                <b-form-checkbox
                    selected
                    v-model="data.passwordSettings.maxLengthActive"
                    switch
                    inline
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>{{ $t("labels.alphanumeric") }}</b-td>

              <b-td>
                <b-form-checkbox
                    selected
                    v-model="data.passwordSettings.requiredAlphaNumeric"
                    switch
                    inline
                >
                </b-form-checkbox>
              </b-td>
            </b-tr>
          </b-table-simple>
        </form>
      </b-modal>

      <b-alert
          :show="modeEdit && data.client == null"
          variant="warning"
          class="p-2"
      >
        {{ $t('whitelabel_assign_customer') }}
        <b-button
            size="sm"
            variant="outline-primary"
            @click="$router.push({ name: 'apps-clients-list' })"
        >
          {{ $t('click_to_assign') }}
        </b-button>
      </b-alert>

      <WhitelabelLimitationModal ref="whitelabelLimitationModal"/>

      <TotalCoinLimitationModal ref="totalCoinLimitationModal"/>

    </b-form>
  </validation-observer>
  <!-- </template>
</b-modal> -->
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BAvatar,
  BAlert,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {min_value} from 'vee-validate/dist/rules';
import {VueEditor} from "vue2-editor";
import {ref, onUnmounted, computed, watch} from "@vue/composition-api";
import {required, alphaNum} from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import whitelabelStoreModule from "./whitelabelStoreModule";
import clientStoreModule from "@/views/pages/clients/clientStoreModule";
import bannerStoreModule
  from "@/views/components/whitelabel-templates/App/config/PageEdit/BannerManagement/external/banners/bannerStoreModule";
import {getUserData, validateTokenRefreshData} from "@/auth/utils";
import axios from "@axios";
import timeZoneData from "@/assets/data/json/timeZone.json";
import router from "@/router";
import WhitelabelLimitationModal from "@/views/pages/limitation/whitelabelLimitation/WhitelabelLimitationModal.vue";
import i18n from "@/libs/i18n";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {isSeePermission} from "@core/libs/acl/utils";
import TotalCoinLimitation from "@/views/pages/limitation/totalCoinLimitation/TotalCoinLimitationModal.vue";
import TotalCoinLimitationModal from "@/views/pages/limitation/totalCoinLimitation/TotalCoinLimitationModal.vue";

//
// const LANG_OPTIONS = [
//   {locale: 'en', name: 'English', disabled: false, flag: 'USA'},
//   {locale: 'es', name: 'Español', disabled: true, flag: 'Spain'},
//   {locale: 'pt', name: 'Português', disabled: true, flag: 'Portugal'}
// ]


extend('isBigger', {
  params: ['target'],
  validate(value, {target}) {
    return value < target;
  },
  message: i18n.t(' alert_time_validator')
});


extend('min_value', min_value);


export default {
  components: {
    TotalCoinLimitationModal,
    TotalCoinLimitation,
    WhitelabelLimitationModal,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAvatar,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    BAlert,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  watch: {
    isAddNewWhitelabelSidebarActive(val) {
      if (val && (this.modeEdit || this.modeShow) && this.data.client !== "") {
        this.getCurrencies(this.data.client);
        this.getBrands();
        this.getProductsByGatewayClientId(this.data.client);
        this.getPaymentMethods(this.data.client);
        this.indexTemplates();
        this.getBanners();
        console.log(this.data.client, "client");
      } else {
        this.modeEdit = false;
        this.modeShow = false;
      }
    },
  },
  async mounted() {
    const option = this.$route.name.includes("detail") ? "show" : "edit";
    this.methoEditShow(this.data, option);
    await this.getWhitelabel(this.$route.params.id).then(() => {
      // if (this.data.currencies.length === 0 && this.data.client !== "" )
      if (option === "edit") {
        this.setLangSelected(this.data.langOptions)
        this.getCurrencies(this.data.client);
        this.getBrands();
        this.getProductsByGatewayClientId(this.data.client);
        this.getPaymentMethods(this.data.client);
        this.indexTemplates();
        this.getBanners();
        this.getTemplateSlugs()
      }
    });
  },
  methods: {
    updateDisabledOptions(option, action) {
      const index = this.data.langOptions.findIndex(item => item.locale === option.locale);
      this.data.langOptions[index].disabled = action === 'deselected'

      if(action === 'deselected' && this.data.langDefault === option.locale) {
        this.data.langDefault = 'en'
      }
    },

    setLangSelected(options) {
      options.forEach(item => {
        if(!item.disabled) {
          this.langSelected.push(item)
        }
      })
    },

    isSeePermission,
    handleSelectUpdate({deselected}) {
      this.getBrands()
      if (deselected) {
        this.upadteBrands()
      }
    },

    upadteBrands() {
      // Extract all valid productIds from the products array
      const validProductIds = this.data.products.map(product => product.productId);
      // Filtrar el array de brands
      // Keep only the brands whose product.productId exists in the validProductIds array
      this.data.brands = this.data.brands.filter(brand => validProductIds.includes(brand.product.productId));
    },

    async getTemplateSlugs() {
      try {
        const templateSlugs = await store.dispatch(
            "app-whitelabel/fetchTemplateSlugs"
        );
        this.templateSlugsOptions = templateSlugs.data
      } catch (error) {
        console.log(error.error);
      }
    },

    async getPaymentMethods(gatewayClientId) {
      try {
        const paymentMethods = await store.dispatch(
            "app-whitelabel/fetchPaymentMethods",
            gatewayClientId
        );
        this.paymentMethodsOptions = paymentMethods.data.map(element => element.product);
      } catch (error) {
        console.log(error.error);
      }
    },

    removeIdKeys(arr) {
      return arr.map(obj => {
        let newObj = {...obj};
        delete newObj._id;
        delete newObj.id;
        delete newObj.product?.product;
        return newObj;
      });
    },

    async getBrands() {
      const selectedProductId = this.data.products.map(p => p.productId)
      try {
        const brandsGet = await store.dispatch(
            "app-whitelabel/fetchBrandsByProducts",
            {
              idClient: this.$store.state.whitelabelCurrencyNabvar.whitelabel.client,
              products: selectedProductId
            }
        );
        let data = brandsGet.data;
        this.brandsOptions = this.removeIdKeys(data).map(item => ({
          ...item,
          nameAndProduct: item.name + ' - ' + item.product.name
        }));
      } catch (error) {
        console.log(
            "🚀 ~ file: WhitelabelListAddNew.vue ~ line 649 ~ getBrands ~ error",
            error
        );
      }
    },

    async getCurrencies(clientId) {
      try {
        const currenciesGet = await store.dispatch(
            "app-whitelabel/fetchCurrencies",
            clientId
        );
        this.currenciesOptions = currenciesGet.data;
      } catch (error) {
        console.log(error.error);
      }
    },

    async getProductsByGatewayClientId(gatewayClientId) {
      try {
        const productObject = await store.dispatch(
            "app-whitelabel/fetchProducts",
            gatewayClientId
        );
        const objects = productObject.data;
        objects.forEach((element) => {
          this.productsOptions.push(element.product);
        });
      } catch (error) {
        console.log(error.error);
      }
    },

    handleAddBanner() {
      this.bannerToAdd.pages = this.pathsSelected.map((path) => path.href);
      this.data.banners
          ? this.data.banners.push(this.bannerToAdd)
          : (this.data.banners = [this.bannerToadd]);
      this.bannerToAdd = {data: null, pages: null};
      this.showAddBannerModal = false;
    },

    async getProducts(ctx, callback) {
      try {
        const productsGet = await store.dispatch(
            "app-whitelabel/fetchProducts"
        );
        this.productsOptions = productsGet.data;
      } catch (error) {
        console.log(error.error);
      }
    },

    async getBanners(ctx, callback) {
      try {
        const bannersGet = await store.dispatch("app-banner/fetchBanners");
        this.bannersOptions = bannersGet.data.banners;
      } catch (error) {
        console.log(error);
      }
    },

    async getWhitelabel(id) {
      try {
        this.loadingWhitelabel = true
        const whitelabel = await store.dispatch(
            "app-whitelabel/fetchWhitelabel",
            {id}
        );
        console.log(
            "🚀 ~ file: WhitelabelListAddNew.vue ~ line 663 ~ getWhitelabel ~ whitelabel",
            whitelabel
        );
        this.data = whitelabel.data;
        this.data.brands = this.removeIdKeys(whitelabel.data.brands)
        localStorage.setItem("dataCache", JSON.stringify(this.data));
        // console.log("🚀 ~ file: WhitelabelListAddNew.vue ~ line 502 ~ getWhitelabel ~ whitelabel", whitelabel)
      } catch (error) {
        console.log(error.error);
      } finally {
        this.loadingWhitelabel = false
      }
    },

    methoEditShow(whitelabel, mode) {
      const fieldData = {};
      console.log(whitelabel);
      if (whitelabel.length > 0) {
        const passwordSettings = {
          maxLength: 0,
          minLength: 4,
          maxLengthActive: false,
          minLengthActive: false,
          requiredAlphaNumeric: false,
        };

        fieldData.firstName =
            whitelabel.fieldData.firstName === true ? true : false;
        fieldData.lastName =
            whitelabel.fieldData.lastName === true ? true : false;
        fieldData.email = whitelabel.fieldData.email === true ? true : false;
        fieldData.address =
            whitelabel.fieldData.address === true ? true : false;
        fieldData.phone = whitelabel.fieldData.phone === true ? true : false;
        fieldData.city = whitelabel.fieldData.city === true ? true : false;
        fieldData.birthDate =
            whitelabel.fieldData.birthDate === true ? true : false;
        fieldData.twoFA =
            whitelabel.fieldData.twoFA === true ? true : false;

        const blankdata = {
          _id: whitelabel._id,
          name: whitelabel.name,
          description: whitelabel.description,
          host: whitelabel.host,
          hostAdmin: whitelabel.hostAdmin,
          type: whitelabel.type,
          products: whitelabel.products,
          brands: whitelabel.brands,
          client: whitelabel.client,
          banners: whitelabel.banners,
          currencies: whitelabel.currencies,
          logo: whitelabel.logo,
          page: {
            header: whitelabel.page.header._id,
            menu: whitelabel.page.menu._id,
            homepage: whitelabel.page.homepage._id,
            footer: whitelabel.page.footer._id,
          },
          required_id: whitelabel.required_id,
          minimumAge: whitelabel.minimumAge,
          expiredSession: whitelabel.expiredSession,
          expiredPassword: whitelabel.expiredPassword,
          politicsPrivacy: whitelabel.politicsPrivacy,
          termsConditions: whitelabel.termsConditions,
          gameRes: whitelabel.gameRes,
          timeZone: whitelabel.timeZone,
          fieldData: {
            firstName: fieldData.firstName,
            lastName: fieldData.lastName,
            email: fieldData.email,
            address: fieldData.address,
            phone: fieldData.phone,
            city: fieldData.city,
            birthDate: fieldData.birthDate,
            twoFA: fieldData.twoFA,
            passwordSettings: passwordSettings,
          },
        };
        this.data = blankdata;
      }
      if (mode == "edit") {
        this.modeEdit = true;
      } else if (mode == "show") {
        this.modeShow = true;
      }
    },
    async onFileChange(event) {
      const formData = new FormData();
      for (const e of event.target.files) {
        formData.append("images", e, e.name);
      }
      formData.append("path", "whitelabel");
      axios
          .post(
              `setImages/${this.$store.state.whitelabelCurrencyNabvar.whitelabel.client}`,
              formData
          )
          .then((response) => {
            this.data = Object.assign({}, this.data);
            this.data.logo = response.data.files[0];
          });
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.handleConfirm();
    },

    handleCancel() {
      try {
        const {passwordSettings, fieldData} = JSON.parse(
            localStorage.getItem("dataCache")
        );
        this.data.passwordSettings = passwordSettings;
        this.data.fieldData = fieldData;
      } catch (e) {
      }
    },

    handleConfirm() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide("my-modal-required");
      });
    },

    checkFormValidity() {
      return this.validationMinLength && this.validationMaxLength;
    },

    setClientLimitModal(flag) {
      this.$refs.whitelabelLimitationModal.setModal(flag, this.data);
    },

    setTotalCoinLimitModal(flag) {
      console.log(this.data)
      this.$refs.totalCoinLimitationModal.setModal(flag, this.data)
    }
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewWhitelabelSidebarActive",
    event: "update:is-add-new-whitelabel-sidebar-active",
  },
  props: {
    isAddNewWhitelabelSidebarActive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    validationMinLength() {
      return Number(this.data.passwordSettings.minLength) >= 4;
    },

    validationMaxLength() {
      const max = Number(this.data.passwordSettings.maxLength);
      const min = Number(this.data.passwordSettings.minLength);
      return max >= min;
    },

    showTitleModal() {
      if (this.modeEdit) {
        return this.$t("tabs.editwhitelabels");
      }
      if (this.modeShow) {
        return this.$t("tabs.showwhitelabels");
      }
      return this.$t("tabs.addwhitelabels");
    },
    options: () => timeZoneData.map((o) => o.name),
  },
  data() {
    return {
      loadingWhitelabel: false,
      dataCache: {},
      langSelected: [],
      required,
      alphaNum,
      test: false,
      showAddBannerModal: false,
      bannerToAdd: {data: null, pages: null},
      pathsSelected: null,
      optionsType: [
        {value: i18n.t('pages'), text: "Page"},
        {value: i18n.t('bets'), text: "Bets"},
      ],
      currenciesOptions: [],
      productsOptions: [],
      paymentMethodsOptions: [],
      templateSlugsOptions: [],
      brandsOptions: [],
      bannersOptions: [],
    };
  },
  setup(props, {emit}) {
    const toast = useToast();
    const MODULE_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME))
      store.registerModule(MODULE_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME))
        store.unregisterModule(MODULE_APP_STORE_MODULE_NAME);
    });

    const CLIENT_APP_STORE_CLIENT_NAME = "app-client";
    if (!store.hasModule(CLIENT_APP_STORE_CLIENT_NAME))
      store.registerModule(CLIENT_APP_STORE_CLIENT_NAME, clientStoreModule);
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_CLIENT_NAME))
        store.unregisterModule(CLIENT_APP_STORE_CLIENT_NAME);
    });

    const BANNER_APP_STORE_CLIENT_NAME = "app-banner";
    if (!store.hasModule(BANNER_APP_STORE_CLIENT_NAME))
      store.registerModule(BANNER_APP_STORE_CLIENT_NAME, bannerStoreModule);
    onUnmounted(() => {
      if (store.hasModule(BANNER_APP_STORE_CLIENT_NAME))
        store.unregisterModule(BANNER_APP_STORE_CLIENT_NAME);
    });

    const clientsOptions = ref([]);

    const banners = ref([]);
    const modeEdit = ref(false);
    const modeShow = ref(false);
    const indexTemplates = () => {
      store.dispatch("templatesModule/indexHeaders", {noPaginate: true});
      store.dispatch("templatesModule/indexMenu", {noPaginate: true});
      store.dispatch("templatesModule/indexHomePages", {noPaginate: true});
      store.dispatch("templatesModule/indexFooters", {noPaginate: true});
    };

    // watch for changes isAddNewWhitelabelSidebarActive
    watch(
        () => props.isAddNewWhitelabelSidebarActive,
        (newValue) => {
          if (newValue) {
            indexTemplates();
          } else {
            resetdata();
          }
        }
    );
    const headersOptions = computed(() => {
      return store.state.templatesModule.headers;
    });
    const menusOptions = computed(() => {
      return store.state.templatesModule.menus;
    });
    const homePagesOptions = computed(() => {
      return store.state.templatesModule.homePages;
    });
    const footersOptions = computed(() => {
      return store.state.templatesModule.footers;
    });

    //const userData = getUserData()
    const blankdata = {
      passwordSettings: {
        maxLength: 0,
        minLength: 4,
        maxLengthActive: false,
        minLengthActive: false,
        requiredAlphaNumeric: false,
      },
      name: "",
      description: "",
      politicsPrivacy: "",
      termsConditions: "",
      gameRes: "",
      timeZone: [],
      fieldData: {
        firstName: false,
        lastName: false,
        email: false,
        address: false,
        phone: false,
        city: false,
        birthDate: false,
      },
      host: [],
      hostAdmin: [],
      type: "",
      products: [],
      banners: [],
      logo: "",
      // users: false,
      client: "",
      // lobby: { rowMaxLength: null },
      currencies: [],
      page: {
        header: null,
        menu: null,
        homepage: null,
        footer: null,
      },
      required_id: false,
      minimumAge: 18,
      expiredPassword: 45,
      alertExpiredPassword: 3,
      // banners: ''
    };

    const data = ref(JSON.parse(JSON.stringify(blankdata)));
    const resetdata = () => {
      data.value = JSON.parse(JSON.stringify(blankdata));
    };

    const validHost = ref("");
    const validHostAdmin = ref("");

    const onSubmit = async () => {
      console.log('Entro');
      try {
        if (modeEdit.value) {
          validHost.value = "";
          validHostAdmin.value = "";
          // validar host y hostAdmin
          if (data.value.host.length == 0) {
            validHost.value = "El host es requerido";
            return;
          }

          if (data.value.hostAdmin.length == 0) {
            validHostAdmin.value = "El host del admin es requerido";
            return;
          }
        }

        if (modeEdit.value) {
          let payload = JSON.parse(JSON.stringify(data.value));

          payload.passwordSettings.minLength =
              data.value.passwordSettings.minLength;
          payload.passwordSettings.maxLength =
              data.value.passwordSettings.maxLength;
          payload.passwordSettings.minLengthActive =
              data.value.passwordSettings.minLengthActive;
          payload.passwordSettings.maxLengthActive =
              data.value.passwordSettings.maxLengthActive;

          // payload.page.header = data.value.page.header._id;
          // payload.page.menu = data.value.page.menu._id;
          // payload.page.homepage = data.value.page.homepage._id;
          // payload.page.footer = data.value.page.footer._id;
          console.log(payload);

          await store.dispatch("app-whitelabel/updateWhitelabel", payload);
        } else {
          const payload = {
            name: data.value.name,
            description: data.value.description,
          };
          await store.dispatch("app-whitelabel/addWhitelabel", payload);
        }

        // emit("refetch-data");
        // emit("update:is-add-new-whitelabel-sidebar-active", false);
        validateTokenRefreshData().then(() => {
          store.commit(
              "whitelabelCurrencyNabvar/SET_WHITELABEL_BY_NAME",
              store.state.whitelabelCurrencyNabvar.whitelabel.name
          );
        });

        toast({
          component: ToastificationContent,
          props: {
            title: "Whitelabel added successfully",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        // redirect to whitelabels list
        router.push({name: "apps-whitelabels-list"});
      } catch (msg) {
        // 401 error
        // if (msg.response.status !== 401) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('Error'),
            text: await translatableText({text: axiosErrorHandle(msg)}),
            icon: "XIcon",
            variant: "danger",
          },
        })
        // }
      }
    };

    const {refFormObserver, getValidationState, resetForm} =
        formValidation(resetdata);

    return {
      data,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      clientsOptions,
      banners,
      headersOptions,
      menusOptions,
      homePagesOptions,
      footersOptions,
      modeEdit,
      modeShow,
      validHost,
      validHostAdmin,
      indexTemplates,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-whitelabel-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.vue-editor .ql-editor {
  max-height: 300px !important;
  overflow-y: auto;
}
</style>
